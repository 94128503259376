@import url('https://fonts.googleapis.com/css?family=Lato');

body {
  font-family: 'Lato', sans-serif;
}

@import 'bootstrap/scss/bootstrap';

// Set your custom font as the base font
$font-family-base: 'Lato', sans-serif;

// Import Bootstrap with the new settings
@import 'bootstrap/scss/bootstrap';

.bg-light {
  background-color: #e9ecef !important;
}

.navbar-brand img {
  height: 30px;
}

.fa {
  color: hsl(222, 59%, 44%);
}

.fan {
  color: #f0f0f0;
}
.homeBtn {
  background-color: #2e55b3;
  color: white;
}

// Make logo smaller for screens for iPhone 5
@media only screen and (max-width: 320px) {
  .navbar-brand img {
    height: 25px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 960px;
  }
}
